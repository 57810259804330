import { Fragment, useEffect, useState } from 'react';
import * as React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';

import PlusIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';

import useMediaQuery from '@mui/material/useMediaQuery/useMediaQuery';
import { RootState } from '../store/store';
import { PageEnum } from '../types/enums';
import { GetFormGroupList } from '../services/form-group-service';
import { IFormGroup } from '../types/form-groups';
import { useSelector } from 'react-redux';
import { IFormGroupListRequest } from '../types/forms';


const FormGroupList = ({ onEditFormGroup }) => {
    const isMobile = !useMediaQuery('(min-width:600px)');
    const user = useSelector((state: RootState) => state.userDetail);
    const userRole = useSelector((state: RootState) => state.roles);
    const currentPage = userRole.userPageAccess[PageEnum.FormGroups];

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [rows, setRows] = useState<IFormGroup[]>([]);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const getFormGroupList = () => {
        const request: IFormGroupListRequest = { customerId: user.customerId };

        GetFormGroupList(request)
            .then(formGroupList => {

                setRows(formGroupList);
            })
            .catch((error) => {
                console.error(error);
            });
    }

    // Perform any setup that requires access to the DOM
    useEffect(() => {
        getFormGroupList();
    }, []);

    return (
        <Fragment>
            <Grid
                container
                justifyContent={'center'}
                spacing={1}
            >
                <Grid item sm={12} className='minWidth'>
                    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                        <TableContainer sx={{ maxHeight: 440 }}>
                            <Table stickyHeader size="small" aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell key="formGroupName">Form Group</TableCell>
                                        <TableCell key="assignedForms">Assigned Forms</TableCell>
                                        <TableCell key="assignedUsers">Assigned Users</TableCell>
                                        {currentPage?.canEdit && <TableCell key="actions" align="center">Actions</TableCell>}
                                    </TableRow>
                                </TableHead>
                                {rows &&
                                    <TableBody>
                                        {rows
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((row: IFormGroup) => {
                                                return (
                                                    <TableRow hover tabIndex={-1} key={row.formGroupId}>
                                                        <TableCell key="formGroupName">{row.formGroupName}</TableCell>
                                                        <TableCell key="assignedForms">{row.formIdList?.length}</TableCell>
                                                        <TableCell key="assignedUsers">{row.userIdList?.length}</TableCell>
                                                        <TableCell key="actions" align="center">
                                                            {currentPage?.canEdit && isMobile && <PlusIcon onClick={e => onEditFormGroup(row)} className="fs20 mr6 sho-icon" />}
                                                            {currentPage?.canEdit && !isMobile && <Button onClick={e => onEditFormGroup(row)} className="sho-button ml10" color='primary' size="small" variant='outlined'>
                                                                <EditIcon className="fs20 mr6 sho-icon" /> Edit Form Group
                                                            </Button>
                                                            }
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>
                                }
                            </Table>
                        </TableContainer>
                        {rows &&
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 100]}
                                component="div"
                                count={rows.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        }
                    </Paper>
                </Grid>
            </Grid>
        </Fragment>
    );
}

export default FormGroupList;

import { useEffect } from 'react';
import { DownloadFileZipFromExcelRedirect } from '../services/form-service';
import { showSnackbar } from '../services/snackbar.service';
import queryString from 'query-string'

function TempFileDownload() {
    const queryValue = queryString.parse(window.location.search);
    const formDataId = queryValue.FormDataId ?? "blank"
    const fieldName = queryValue.FieldName ?? "blank"

    // Perform any setup that requires access to the DOM
    useEffect(() => {
        const closeTab = () => {
            window.opener = null;
            window.open("", "_self");
            window.close();
        };

        const getZip = async () => {
            try {
                await DownloadFileZipFromExcelRedirect(formDataId, fieldName);
                closeTab();
            } catch (error) {
                showSnackbar('Error fetching data', 'error', 5000);
            }
        };

        getZip();
    }, []);

    return (
        <div className='page-header'>
            Download Files Here
        </div>
    );
}

export default TempFileDownload;

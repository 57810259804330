// useNetworkSync.ts
import { useEffect } from 'react';
import indexedDBService from '../services/indexdb-service'; // Import your IndexedDB service
import { AddUpdateFormData } from '../services/form-service';
import { ISavedRequest } from '../types/forms';

const sendDataToServer = async (body: { data: any; path: string; type: string }) => {
    const { data } = body;
    const req = { ...data };
    const resp = await AddUpdateFormData(req);
    return resp;
};

export const syncData = async () => {
    const storedData = await indexedDBService.getItem('Sync', 'AddUpdateFormData') as ISavedRequest[];
    if (storedData && storedData.length > 0) {
        const userJson = localStorage.getItem("user");
        if (userJson) {
            const user = JSON.parse(atob(userJson));
            const currentUserData = storedData.filter((x: ISavedRequest) => x.data.customerId === user?.customerId);
            for (const item of currentUserData) {
                try {
                    item.data.saveAsDraftToDevice = false; // since we are forcing a sync to server with this request
                    await sendDataToServer(item); // this method will resave the record locally if it fails
                } catch (error) {
                    console.error('Error sending data to the server:', error);
                }
            }
        }
    }
};

export const useNetworkSync = () => {
    useEffect(() => {
        // if (navigator.onLine) {
        //     syncData();
        // }

        // window.addEventListener('online', syncData);

        // return () => {
        //     window.removeEventListener('online', syncData);
        // };
    }, []);
};

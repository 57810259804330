import React, { useState, useEffect } from 'react';
import { Button, Checkbox, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { GetRolePageAccessRights, saveRolePageAccessRight } from '../../services/manage-service';
import { IRoleData, IRolePageAccessRight } from '../../types/manage';
import Classes from './RolesManagement.module.scss';
import { showSnackbar } from '../../services/snackbar.service';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { RoleEnum } from '../../types/enums';

const RolesManagement = (props) => {
    const [rowData, setRowData] = useState<IRoleData[]>([]);

    const user = useSelector((state: RootState) => state.userDetail);

    useEffect(() => {
        if (user && user.roleId === RoleEnum.SuperAdmin) {
            const fetchData = async () => {
                try {
                    const response: IRoleData[] = await GetRolePageAccessRights() ?? [];
                    setRowData(response);
                } catch (error) {
                    showSnackbar('Error fetching roles', 'error', 5000);
                }
            };
            fetchData();
        }
    }, [user]);

    const handleCheckboxChange = (roleIndex, accessIndex, permission) => (event) => {
        const newData = [...rowData];
        newData[roleIndex].rolePageAccessRightList[accessIndex][permission] = event.target.checked;
        setRowData(newData);
    };

    const saveRoles = async () => {
        try {
            const rolePageAccessRights: IRolePageAccessRight[] = rowData.flatMap(item => item.rolePageAccessRightList);
            await saveRolePageAccessRight(rolePageAccessRights);
            showSnackbar('Saved Successfully', 'success', 5000);
        } catch (error) {
            showSnackbar('Error saving data', 'error', 5000);
        }
    }

    return (
        <div>
            {user && user.roleId === RoleEnum.SuperAdmin ? <div>
                <div className={Classes.rolesHeader}>
                    <h2 style={{ fontWeight: 'normal' }}>Roles Management</h2>
                    {/* <Typography variant="h6">Roles Management</Typography> */}
                    <Button variant="contained" color="primary" onClick={saveRoles}>
                        Save
                    </Button>
                </div>
                <Grid container justifyContent={'center'} spacing={1} style={{ padding: "20px" }}>
                    {rowData.map((roleObj, roleIndex) => (
                        <Grid item sm={12} key={roleObj.role.roleId}>
                            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                                <TableContainer sx={{ maxHeight: 440 }}>
                                    <Table stickyHeader size="small" aria-label="sticky table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell colSpan={5}>
                                                    <div style={{ fontSize: '16px', marginTop: "10px" }}>
                                                        <span style={{ fontWeight: 'bold' }}>{roleObj.role.roleName}</span>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell sx={{ fontWeight: 'bold' }}>Page Name</TableCell>
                                                <TableCell sx={{ fontWeight: 'bold' }}>View</TableCell>
                                                <TableCell sx={{ fontWeight: 'bold' }}>Add</TableCell>
                                                <TableCell sx={{ fontWeight: 'bold' }}>Edit</TableCell>
                                                <TableCell sx={{ fontWeight: 'bold' }}>Delete</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {roleObj.rolePageAccessRightList.map((pageAccess, accessIndex) => (
                                                <TableRow key={accessIndex}>
                                                    <TableCell>{pageAccess.pageName}</TableCell>
                                                    <TableCell>
                                                        <Checkbox
                                                            checked={pageAccess.canView}
                                                            onChange={handleCheckboxChange(roleIndex, accessIndex, 'canView')}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <Checkbox
                                                            checked={pageAccess.canAdd}
                                                            onChange={handleCheckboxChange(roleIndex, accessIndex, 'canAdd')}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <Checkbox
                                                            checked={pageAccess.canEdit}
                                                            onChange={handleCheckboxChange(roleIndex, accessIndex, 'canEdit')}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <Checkbox
                                                            checked={pageAccess.canDelete}
                                                            onChange={handleCheckboxChange(roleIndex, accessIndex, 'canDelete')}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
            </div> : <h1>You don't have access to Roles Management Page</h1>}
        </div>
    );
};

export default RolesManagement;

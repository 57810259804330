import { Fragment, memo, useEffect, useState } from 'react';
import { Button, InputLabel } from '@mui/material';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import { ReactElement } from 'react';
import { withJsonFormsControlProps } from '@jsonforms/react';
import AttachFileIcon from '@mui/icons-material/AttachFile';

interface ISelectedFile {
    fileId: number;
    base64: string;
    name: string;
}

const MediaCaptureControl = memo((props: any): ReactElement => {
    const {
        data,
        label,
        id,
        enabled,
        uischema,
        errors,
        handleChange,
        options,
        path,
        schema,
        uischemas,
    } = props;

    const [files, setFiles] = useState<ISelectedFile[]>([]);
    const [accept, setAccept] = useState<string>("image/*");

    const handleCapture = async ($event: any) => {

        const fileList: FileList = $event.target.files;

        const selectedFiles: ISelectedFile[] = [...files];
        await Array.from(fileList).forEach(async (file: File) => {
            if (selectedFiles.findIndex(x => x.name === file.name) === -1) {
                const base64: string = await toBase64(file) as string;
                const selectedFile: ISelectedFile = { fileId: 0, base64: base64, name: file.name };

                selectedFiles.push(selectedFile);

                setFiles([...selectedFiles]);
                handleChange(path, selectedFiles);
            }
        });
    }

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    const handleDelete = (removeIndex: number) => () => {
        files.splice(removeIndex, 1);
        setFiles([...files]);
        handleChange(path, [...files]);
    };

    useEffect(() => {
        if (uischema && uischema.options && uischema.options.accept)
            setAccept(uischema.options.accept);

        if (data && data.length > 0) {
            setFiles([...data]);
        }
    }, [schema, uischema]);

    return (
        <div className="media-capture-container">
            <Fragment>
                <InputLabel >{label}</InputLabel>
                <label htmlFor={`icon_${props.path}`} className="icon-button-photo">
                    <Button component="label" variant="outlined" startIcon={<AttachFileIcon />}>
                        Select File
                        <input
                            accept={accept}
                            style={{ display: 'none' }}
                            id={`icon_${props.path}`}
                            onChange={handleCapture}
                            type="file"
                            multiple
                        />
                    </Button>
                </label>
                {files && files.length > 0 && <Stack className='selected-files-container' direction="row" flexWrap="wrap" spacing={1} gap={1}>
                    {files.map((file: ISelectedFile, index: number) =>
                        <Chip
                            key={file.name}
                            label={file.name}
                            variant="outlined"
                            onDelete={handleDelete(index)}
                        />
                    )}
                </Stack>}
            </Fragment>
        </div>
    );
});

export default withJsonFormsControlProps(MediaCaptureControl);

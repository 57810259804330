import ShoFormsClient from '../httpClient/ShoFormsClient';
import indexedDBService from './indexdb-service';

export const GetUser = async () => {
    try {
        const instance = ShoFormsClient();

        let emailString = localStorage.getItem("email");

        if (!emailString)
            emailString = "";



        let payload = { email: emailString };


        let response = await instance.post('/Manage/GetUser', payload);

        await indexedDBService.setItem("General", "User", response.data);

        return response.data;
    } catch (error) {
        if (!navigator.onLine) {
            const indexStorageData = await indexedDBService.getItem("General", "User");
            return indexStorageData || [];
        } else {
            throw error;
        }
    }
};


export const GetUserPageAccess = async () => {
    try {
        const instance = ShoFormsClient();

        let emailString = localStorage.getItem("email");


        if (!emailString)
            emailString = "";

        let payload = { email: emailString };


        let response = await instance.post('/Manage/GetUserPageAccess', payload);

        await indexedDBService.setItem("General", "UserPageAccess", response.data);

        return response.data;
    } catch (error) {
        if (!navigator.onLine) {
            const indexStorageData = await indexedDBService.getItem("General", "UserPageAccess");
            return indexStorageData || [];
        } else {
            throw error;
        }
    }
};

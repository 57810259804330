import { Fragment, useState, useEffect } from 'react';
import MonacoEditor from '@monaco-editor/react';
import Grid from '@mui/material/Grid';
import PageHeader from '../components/PageHeader';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { useStyles } from "../common/appStyles";
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';

// import Ajv from "ajv";
import FormPreview from '../components/FormPreview';
import { useParams, useNavigate } from 'react-router';
import { IFormSchemaDetail, IFormSchemaDetailRequest, ISaveFormDetailRequest } from '../types/forms';
import { showSnackbar } from '../services/snackbar.service';
import { GetFormSchemaDetail, SaveFormDetail } from '../services/form-service';
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';
import { PageEnum } from '../types/enums';
import { ICustomer } from '../types/common';
import { getCustomerList } from '../services/customer.service';

function FormBuilder(props) {
    const classes = useStyles();
    const user = useSelector((state: RootState) => state.userDetail);
    const userRole = useSelector((state: RootState) => state.roles);
    const currentPage = userRole.userPageAccess[PageEnum.FormBuilder];

    const navigate = useNavigate();
    const { formId } = useParams()

    const monacoEditorOptions = {
        minimap: { enabled: false, },
        automaticLayout: true,
    };

    const [pageTitle] = useState("Form Builder");

    const [isDark, setIsDark] = useState<boolean>(false);
    const [editMode, setEditMode] = useState<boolean>(false);

    const [schemaValid, setSchemaValid] = useState<boolean>(false);
    const [uischemaValid, setUiSchemaValid] = useState<boolean>(false);

    const [loadJsonForm, setLoadJsonForm] = useState<boolean>(false);
    const [schema, setSchema] = useState<any>(null);
    const [uischema, setUischema] = useState<any>(null);
    const [data] = useState<any>({});

    const [defaultSchema, setDefaultSchema] = useState<string>("{}");
    const [defaultLayoutSchema, setDefaultLayoutSchema] = useState<string>("{}");

    const [formDetail, setFormDetail] = useState<IFormSchemaDetail>({
        formId: 0,
        customerIdList: [],
        formName: "",
        formDescription: "",
        schemaId: 0,
        layoutSchemaId: 0,
        formSchemaJson: "",
        formLayoutSchemaJson: "",
    });

    const onRefreshForm = () => {
        // const schemaObj = JSON.parse(schemaInput);
        // const uischemaObj = JSON.parse(uiSchemaInput);
        // setSchema(schemaObj);
        // setUischema(uischemaObj);
        // setLoadJsonForm(true);

        // const schemaValidator = ajv.compile(schema);
        // const uischemaValidator = ajv.compile(uischema);

        localStorage.setItem("FB_Schema", JSON.stringify(schema));
        localStorage.setItem("FB_LayoutSchema", JSON.stringify(uischema));

        setLoadJsonForm(false);
        setTimeout(() => {
            setLoadJsonForm(true);
        }, 500);
    }

    const onBack = () => { }


    const onSchemaInputChange = (code) => {
        try {
            const parsedCode = JSON.parse(code);
            setSchema(parsedCode);
            setSchemaValid(true);
        } catch (err) {
            // this.setState({ valid: false, code });
            setSchemaValid(false);
        }
    };

    const onLayoutSchemaInputChange = (code) => {
        try {
            const parsedCode = JSON.parse(code);
            setUischema(parsedCode);
            setUiSchemaValid(true);
        } catch (err) {
            // this.setState({ valid: false, code });
            setUiSchemaValid(false);
        }
    };

    const onSaveFormDetail = async () => {
        // setFormDetail({ ...formDetail, formSchemaJson: JSON.stringify(schema), formLayoutSchemaJson: JSON.stringify(uischema) });
        // setFormDetail({ ...formDetail, });

        const request: ISaveFormDetailRequest = {
            formId: formDetail.formId,
            formName: formDetail.formName,
            formDescription: formDetail.formDescription,
            schemaId: formDetail.schemaId,
            formSchemaJson: JSON.stringify(schema),
            layoutSchemaId: formDetail.layoutSchemaId,
            formLayoutSchemaJson: JSON.stringify(uischema),
            customerId: user.customerId,
            userId: user.userId,
            customerIdList: formDetail.customerIdList,
        };

        // await axios.post(appConfig.API_URL + 'Form/SaveFormDetail', request)
        await SaveFormDetail(request)
            .then(response => {
                showSnackbar('Saved Successfully', 'success', 5000);
                navigate("/form-list");
            })
            .catch(error => {
                console.error(error);
            });
    }

    const getFormSchemaDetail = async (formId: number) => {
        const request: IFormSchemaDetailRequest = {
            customerId: user.customerId,
            userId: user.userId,
            formId: formId
        };
        const resp = await GetFormSchemaDetail(request);
        const formSchemaDetail: IFormSchemaDetail = resp.data.formSchemaDetail;
        setFormDetail(formSchemaDetail);
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormDetail({ ...formDetail, [name]: value });
    };

    const [customers, setCustomers] = useState<ICustomer[]>([]);
    const setSelectedCustomer = (event) => {
        // formDetail.customerIdList = event.target.value as number[];
        formDetail.customerIdList = event.target.value as number[];
        setFormDetail({ ...formDetail });
    };

    // Perform any setup that requires access to the DOM
    useEffect(() => {
        if (formId && parseFloat(formId) > 0) {
            setEditMode(true);
            getFormSchemaDetail(parseFloat(formId));
        } else {
            setEditMode(false);
            setDefaultSchema(localStorage.getItem("FB_Schema") as string);
            setDefaultLayoutSchema(localStorage.getItem("FB_LayoutSchema") as string);

            setFormDetail({ ...formDetail, formSchemaJson: localStorage.getItem("FB_Schema")?.toString() as string });
            setFormDetail({ ...formDetail, formLayoutSchemaJson: localStorage.getItem("FB_LayoutSchema")?.toString() as string });
        }

        const fetchData = async () => {
            try {
                const request = {
                    userId: user.userId
                }
                const customerList: ICustomer[] = await getCustomerList(request) ?? [];
                setCustomers(customerList);
            } catch (error) {
                showSnackbar('Error fetching data', 'error', 5000);
            }
        };
        fetchData();
    }, []);

    return (
        <Fragment>
            <Grid
                container
                justifyContent={'center'}
                spacing={1}
                className={classes.container}
            >
                {currentPage?.canView &&
                    <Grid item sm={12} >
                        <Grid item sm={12} className='page-header-container justify-center'>
                            <PageHeader title={pageTitle} showBackButton={false} onBackClick={onBack} />
                            {!editMode && currentPage?.canAdd && <Button className='btn-save' onClick={onSaveFormDetail} variant="contained">
                                Save Form
                            </Button>}
                            {editMode && currentPage?.canEdit && <Button className='btn-save' onClick={onSaveFormDetail} variant="contained">
                                Update Form
                            </Button>}
                            <Button className='btn-theme-toggle' onClick={() => setIsDark(!isDark)} variant="contained">
                                {isDark ? "Dark Mode" : "Light Mode"}
                            </Button>
                        </Grid>

                        <Grid item sm={4}>
                            <FormControl fullWidth sx={{ m: 1 }}>
                                <TextField value={formDetail.formName} name="formName" onChange={handleChange} label="Form Name" variant="outlined" />
                            </FormControl>
                        </Grid>

                        <Grid item sm={8}>
                            <FormControl fullWidth sx={{ m: 1 }}>
                                <InputLabel>Customer(s)</InputLabel>
                                <Select
                                    value={formDetail.customerIdList}
                                    label="Customer"
                                    onChange={(event) => setSelectedCustomer(event)}
                                    multiple={true}
                                >
                                    {customers.map((option: ICustomer) => (
                                        <MenuItem key={option.customerId} value={option.customerId}>
                                            {option.customerName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item sm={8}>
                            <FormControl fullWidth sx={{ m: 1 }}>
                                <TextField value={formDetail.formDescription} name="formDescription" onChange={handleChange} label="Form Description" variant="outlined" />
                            </FormControl>
                        </Grid>

                        <Grid item sm={12}>
                            <Accordion defaultExpanded={true}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="schema-setup"
                                    id="schema-setup"
                                >
                                    <Typography>Schema and Layout Setup</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={2} >
                                        <Grid item md={6} xs={12}>
                                            <Typography variant="h6" component="div">Schema</Typography>
                                            <MonacoEditor
                                                language='json'
                                                value={formDetail.formSchemaJson}
                                                theme={isDark ? "vs-dark" : "vs-light"}
                                                onChange={onSchemaInputChange}
                                                height={400}
                                                options={monacoEditorOptions}
                                            />
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <Typography variant="h6" component="div"> UI Layout Schema </Typography>
                                            <MonacoEditor
                                                language='json'
                                                value={formDetail.formLayoutSchemaJson}
                                                theme={isDark ? "vs-dark" : "vs-light"}
                                                onChange={onLayoutSchemaInputChange}
                                                height={400}
                                                options={monacoEditorOptions}
                                            />
                                        </Grid>
                                        <Grid item md={12} textAlign="center">
                                            <Button onClick={onRefreshForm} disabled={!schemaValid || !uischemaValid} variant="contained" endIcon={<RefreshIcon />}>
                                                Generate Form
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion defaultExpanded={true}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2a-content"
                                    id="form-preview"
                                >
                                    <Typography>Form Preview</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {loadJsonForm
                                        ? <FormPreview
                                            schema={schema}
                                            uischema={uischema}
                                            data={data} />
                                        : 'Enter Schema and UI Schema and click Generate Form to generate form Preview'}
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    </Grid>
                }
                {!currentPage?.canView && <h1>You don't have access to Form Builder</h1>}
            </Grid>
        </Fragment>
    );
}

export default FormBuilder;

// indexedDBService.ts

interface IIndexedDBService {
  setItem: (storeName: string, key: string, value: any) => Promise<void>;
  getItem: (storeName: string, key: string) => Promise<any | null>;
  updateItem: (storeName: string, key: string, value: any) => Promise<void>;
  deleteItem: (storeName: string, key: string) => Promise<void>;
}

const DB_NAME = "ShoFormsDb";
const STORE_NAMES = ["General", "Master", "Sync", "User", "File"];

const openDB = (): Promise<IDBDatabase> =>
  new Promise((resolve, reject) => {
    const request = indexedDB.open(DB_NAME, 1);

    request.onupgradeneeded = () => {
      const db = request.result;
      for (const storeName of STORE_NAMES) {
        if (!db.objectStoreNames.contains(storeName)) {
          db.createObjectStore(storeName, { keyPath: "key" });
        }
      }
    };

    request.onsuccess = () => {
      resolve(request.result);
    };

    request.onerror = () => {
      reject(request.error);
    };
  });

const indexedDBService: IIndexedDBService = {
  setItem: async (storeName: string, key: string, value: any) => {
    const db = await openDB();
    const transaction = db.transaction(storeName, "readwrite");
    const store = transaction.objectStore(storeName);

    const record = { key, value };
    store.put(record);

    return new Promise<void>((resolve, reject) => {
      transaction.oncomplete = () => {
        resolve();
      };

      transaction.onerror = () => {
        reject(transaction.error);
      };
    });
  },

  getItem: async (storeName: string, key: string): Promise<any | null> => {
    const db = await openDB();
    const transaction = db.transaction(storeName, "readonly");

    if (!db.objectStoreNames.contains(storeName)) {
      db.createObjectStore(storeName, { keyPath: 'key' });
    }

    const store = transaction.objectStore(storeName);
    const request = store.get(key);

    return new Promise<any | null>((resolve, reject) => {
      request.onsuccess = () => {
        resolve(request.result?.value || null);
      };

      request.onerror = () => {
        reject(request.error);
      };
    });
  },

  updateItem: async (storeName: string, key: string, value: any) => {
    return indexedDBService.setItem(storeName, key, value);
  },

  deleteItem: async (storeName: string, key: string) => {
    const db = await openDB();
    const transaction = db.transaction(storeName, "readwrite");
    const store = transaction.objectStore(storeName);
    store.delete(key);

    return new Promise<void>((resolve, reject) => {
      transaction.oncomplete = () => {
        resolve();
      };

      transaction.onerror = () => {
        reject(transaction.error);
      };
    });
  },
};

export default indexedDBService;

import { Fragment, useEffect, useState } from 'react';
import * as React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';

import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import EditIcon from '@mui/icons-material/Edit';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import SettingsIcon from '@mui/icons-material/Settings';
import { useAuth0 } from '@auth0/auth0-react';

import { GetFormDetailList } from '../services/form-service';

import FormSettingDialog from '../components/FormSettingDialog';

import { IForm, IFormListRequest, IFormGroupListRequest } from '../types/forms';
import useMediaQuery from '@mui/material/useMediaQuery/useMediaQuery';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { RootState } from '../store/store';
import { PageEnum, RoleEnum } from '../types/enums';
import { showSnackbar } from '../services/snackbar.service';
import { GetFormGroupList } from '../services/form-group-service';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { IFormGroup } from '../types/form-groups';
import { updateSelectedForm } from '../store/selectedFormSlice';
import { DownloadExcel } from '../services/form-service';
import { IUserDetail } from '../types/reduxStore';
import indexedDBService from '../services/indexdb-service';


const FormList = ({ onAddNewEntry }) => {
    const isMobile = !useMediaQuery('(min-width:600px)');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [user, setUser] = useState<IUserDetail>(useSelector((state: RootState) => state.userDetail))
    const userRole = useSelector((state: RootState) => state.roles);
    const currentPage = userRole.userPageAccess[PageEnum.MyForms];

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [formListMaster, setFormListMaster] = useState<IForm[]>([]);
    const [formListDisplay, setFormListDisplay] = useState<IForm[]>([]);
    const [selectedForm, setSelectedForm] = useState<IForm>();

    const [formGroupList, setFormGroupList] = useState<IFormGroup[]>([]);
    const [selectedFormGroupId, setSelectedFormGroupId] = useState<number>(0);

    const [showFormSettingDialog, setShowFormSettingDialog] = useState(false);

    const { isLoading } = useAuth0();

    const waitForAuth0Loading = async (): Promise<void> => {
        return new Promise<void>((resolve) => {
            const checkInterval = 10; // check every 10 milliseconds
            const intervalId = setInterval(async () => {
                const idbUser = await indexedDBService.getItem('General', 'User')
                if (!isLoading && idbUser != null) {
                    clearInterval(intervalId);
                    resolve();
                }
            }, checkInterval);
        });
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const showEditForm = () => {
        return [RoleEnum.SuperAdmin, RoleEnum.ShoAdmin, RoleEnum.ShoUser, RoleEnum.CustomerAdmin].indexOf(user.roleId) >= 0;
    }

    const canProceedToFormSettings = () => {
        if (user.pageAccess) {
            let u = user.pageAccess.find(item => item.pageCode === PageEnum.FormSettings && item.canView === true);
            if (u) {
                return true
            }
            else {
                return false
            }
        }
        return false
    }

    const updateFormsList = (selectedFormGroupIdParam: number, formListMasterParam: IForm[]) => {
        if (selectedFormGroupIdParam > 0) {
            const selectedFormGroup: IFormGroup = formGroupList.find(x => x.formGroupId === selectedFormGroupIdParam)!;
            if (selectedFormGroup) {

                const filteredFormListByGroup: IForm[] = formListMaster.filter(x => selectedFormGroup.formIdList.indexOf(x.formId) >= 0 && selectedFormGroup.userIdList.indexOf(user.userId) >= 0);
                setFormListDisplay(filteredFormListByGroup);
            }
        } else {
            setFormListDisplay(formListMasterParam);
        }
    }

    const onFormGroupChange = async (selectedFormGroupId: number) => {

        setSelectedFormGroupId(selectedFormGroupId)
        updateFormsList(selectedFormGroupId, formListMaster);
    }

    const onEditForm = (row: IForm) => {
        dispatch(updateSelectedForm(row));
        navigate("/form-builder/" + row.formId);
    }

    const onDownloadExcel = (row: IForm) => {
        DownloadExcel(row.customerId, row.formId)
    }

    const onViewFormEntries = (row: IForm) => {
        dispatch(updateSelectedForm(row));
        navigate("/form-entries/" + row.formId);
    }

    const onViewSettings = (row: IForm) => {
        dispatch(updateSelectedForm(row));
        setSelectedForm(row);
        setShowFormSettingDialog(true);
    }

    const handleCloseFormSetting = () => {
        setShowFormSettingDialog(false)
    }

    const handleSaveFormSetting = async () => {
        try {
            showSnackbar('Saved Successfully', 'success', 5000);
            setShowFormSettingDialog(false);
        } catch (error) {
            showSnackbar('Failed to save Form Setting', 'error', 5000);
        }
    }

    const getFormGroupListWithDefault = async () => {
        const request: IFormGroupListRequest = { customerId: user.customerId };
        const formGroupList: IFormGroup[] = await GetFormGroupList(request);
        formGroupList.splice(0, 0, { formGroupId: 0, formGroupName: "All Group(s)", formIdList: [], userIdList: [] });
        return formGroupList;
    }

    let isLoaded = false;

    useEffect(() => {
        if (!isLoaded) {
            isLoaded = true;
            const fetchData = async () => {
                try {

                    await waitForAuth0Loading()

                    let u = await indexedDBService.getItem('General', 'User')
                    let userDetail = u.userDetail

                    setUser(userDetail)
                    const request: IFormListRequest = {
                        customerId: userDetail.customerId,
                        userId: userDetail.userId
                    };
                    let formListMasterResponse: IForm[] = await GetFormDetailList(request);

                    setFormListMaster(formListMasterResponse)

                    let formGroupListResponse: IFormGroup[] = await getFormGroupListWithDefault();
                    let firstformGroupId: number = formGroupListResponse[0]?.formGroupId;

                    setSelectedFormGroupId(firstformGroupId);
                    setFormGroupList(formGroupListResponse);

                    updateFormsList(firstformGroupId, formListMasterResponse);


                } catch (error) {
                    showSnackbar('Error fetching data', 'error', 5000);
                }
            };
            fetchData();
        }
    }, []);

    return (
        <Fragment>
            {selectedFormGroupId > -1 && formGroupList && <Grid item sm={4}>
                <FormControl fullWidth>
                    <InputLabel>Form Group</InputLabel>
                    <Select
                        labelId="form-group-label"
                        value={selectedFormGroupId}
                        label="Form Group"
                        onChange={(event) => onFormGroupChange(Number(event.target.value))}
                    >
                        {formGroupList?.map((option: IFormGroup) => (
                            <MenuItem key={option.formGroupId} value={option.formGroupId}>
                                {option.formGroupName}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>}
            <Grid
                container
                justifyContent={'center'}
                spacing={1}
            >
                {currentPage?.canView && <Grid item sm={12}>
                    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                        <TableContainer sx={{ maxHeight: 440 }}>
                            <Table stickyHeader size="small" aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell key="formName">Form Name</TableCell>
                                        <TableCell key="formDescription">Form Description</TableCell>
                                        <TableCell key="totalRecords">Total Records</TableCell>
                                        <TableCell key="actions" align="center">Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                {formListDisplay &&
                                    <TableBody>
                                        {formListDisplay
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((row: IForm) => {
                                                return (
                                                    <TableRow hover tabIndex={-1} key={row.customerId + '_' + row.formId}>
                                                        <TableCell key="formName">{row.formName}</TableCell>
                                                        <TableCell key="formDescription">{row.formDescription}</TableCell>
                                                        <TableCell key="totalRecords">{row.totalRecords}</TableCell>
                                                        <TableCell key="actions" align="center">
                                                            {isMobile && <VisibilityOutlinedIcon onClick={e => onViewFormEntries(row)} className="fs20 mr6 sho-icon" color='primary' />}
                                                            {!isMobile && <Button onClick={e => onViewFormEntries(row)} className="sho-button" color='primary' size="small" variant='outlined'>
                                                                <VisibilityOutlinedIcon className="fs20 mr6 sho-icon" color='primary' />
                                                                View All Entries
                                                            </Button>}

                                                            {isMobile && currentPage?.canAdd && <AddCircleOutlineOutlinedIcon onClick={e => onAddNewEntry(row)} className="fs20 mr6 sho-icon" color='primary' />}
                                                            {!isMobile && currentPage?.canAdd && <Button onClick={e => onAddNewEntry(row)} className="sho-button ml10" color='primary' size="small" variant='outlined'>
                                                                <AddCircleOutlineOutlinedIcon className="fs20 mr6 sho-icon" color='primary' />
                                                                Add Entry
                                                            </Button>}

                                                            {isMobile && canProceedToFormSettings() && currentPage?.canAdd && <SettingsIcon onClick={e => onViewSettings(row)} className="fs20 mr6 sho-icon" color='primary' />}
                                                            {!isMobile && canProceedToFormSettings() && currentPage?.canAdd && <Button onClick={e => onViewSettings(row)} className="sho-button ml10" color='primary' size="small" variant='outlined'>
                                                                <SettingsIcon className="fs20 mr6 sho-icon" color='primary' />
                                                                Settings
                                                            </Button>}

                                                            {showEditForm() && isMobile && <EditIcon onClick={e => onEditForm(row)} className="fs20 mr6 sho-icon" color='primary' />}
                                                            {showEditForm() && !isMobile && <Button onClick={e => onEditForm(row)} className="sho-button ml10" color='primary' size="small" variant='outlined'>
                                                                <EditIcon className="fs20 mr6 sho-icon" color='primary' />
                                                                Edit Form
                                                            </Button>}

                                                            {showEditForm() && isMobile && <FileDownloadIcon onClick={e => onDownloadExcel(row)} className="fs20 mr6 sho-icon" color='primary' />}
                                                            {showEditForm() && !isMobile && <Button onClick={e => onDownloadExcel(row)} className="sho-button ml10" color='primary' size="small" variant='outlined'>
                                                                <FileDownloadIcon className="fs20 mr6 sho-icon" color='primary' />
                                                                Download Excel
                                                            </Button>}
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>
                                }
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={formListDisplay.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                </Grid>}

                {!currentPage?.canView && <h1>Loading Form List....</h1>}
            </Grid>

            {showFormSettingDialog && <FormSettingDialog
                showDialog={showFormSettingDialog}
                selectedForm={selectedForm}
                handleClose={handleCloseFormSetting}
                handleSave={handleSaveFormSetting} />}
        </Fragment>
    );
}

export default FormList;

import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Outlet } from "react-router-dom";
import { Button, hexToRgb, } from '@mui/material';
import { TopNav } from './main-layout/TopNav';
import { SideNav } from './main-layout/SideNav';
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';
import { useAuth0 } from '@auth0/auth0-react';
import { appConfig } from '../services/config.service';
import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
import { blue } from '@mui/material/colors';
import { CapacitorCookies } from '@capacitor/core';
import indexedDBService from '../services/indexdb-service';

//const drawerWidth = 240;

const SIDE_NAV_WIDTH = 240;

const LayoutRoot = styled('div')(({ theme }) => ({
    display: 'flex',
    flex: '1 1 auto',
    maxWidth: '100%',
    [theme.breakpoints.up('lg')]: {
        paddingLeft: SIDE_NAV_WIDTH
    }
}));

const LayoutContainer = styled('div')({
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
    width: '100%'
});


//const openedMixin = (theme: Theme): CSSObject => ({
//    width: drawerWidth,
//    transition: theme.transitions.create('width', {
//        easing: theme.transitions.easing.sharp,
//        duration: theme.transitions.duration.enteringScreen,
//    }),
//    overflowX: 'hidden',
//});

//const closedMixin = (theme: Theme): CSSObject => ({
//    transition: theme.transitions.create('width', {
//        easing: theme.transitions.easing.sharp,
//        duration: theme.transitions.duration.leavingScreen,
//    }),
//    overflowX: 'hidden',
//    width: `calc(${theme.spacing(7)} + 1px)`,
//    [theme.breakpoints.up('sm')]: {
//        width: `calc(${theme.spacing(8)} + 1px)`,
//    },
//});

//const DrawerHeader = styled('div')(({ theme }) => ({
//    display: 'flex',
//    alignItems: 'center',
//    justifyContent: 'flex-end',
//    padding: theme.spacing(0, 1),
//    // necessary for content to be below app bar
//    ...theme.mixins.toolbar,
//}));

//interface AppBarProps extends MuiAppBarProps {
//    open?: boolean;
//}

// const AppBar = styled(MuiAppBar, {
//     shouldForwardProp: (prop) => prop !== 'open',
// })<AppBarProps>(({ theme, open }) => ({
//     zIndex: theme.zIndex.drawer + 1,
//     transition: theme.transitions.create(['width', 'margin'], {
//         easing: theme.transitions.easing.sharp,
//         duration: theme.transitions.duration.leavingScreen,
//     }),
//     ...(open && {
//         marginLeft: drawerWidth,
//         width: `calc(100% - ${drawerWidth}px)`,
//         transition: theme.transitions.create(['width', 'margin'], {
//             easing: theme.transitions.easing.sharp,
//             duration: theme.transitions.duration.enteringScreen,
//         }),
//     }),
// }));

// const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
//     ({ theme, open }) => ({
//         width: drawerWidth,
//         flexShrink: 0,
//         whiteSpace: 'nowrap',
//         boxSizing: 'border-box',
//         ...(open && {
//             ...openedMixin(theme),
//             '& .MuiDrawer-paper': openedMixin(theme),
//         }),
//         ...(!open && {
//             ...closedMixin(theme),
//             '& .MuiDrawer-paper': closedMixin(theme),
//         }),
//     }),
// );

export default function MiniDrawer() {
    //const theme = useTheme();
    const [open, setOpen] = React.useState(true);

    const { logout } = useAuth0();

    const user = useSelector((state: RootState) => state.userDetail);

    //const navigate = useNavigate();

    //const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    //const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

    //const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    //    setAnchorElNav(event.currentTarget);
    //};

    //const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    //    setAnchorElUser(event.currentTarget);
    //};

    //const handleCloseNavMenu = () => {
    //    setAnchorElNav(null);
    //};

    //const handleCloseUserMenu = () => {
    //    setAnchorElUser(null);
    //};

    //const handleDrawerOpen = () => {
    //    setOpen(true);
    //};

    //const handleDrawerClose = () => {
    //    setOpen(false);
    //};

    const clearCookiesOnUrl = async () => {
        await CapacitorCookies.clearCookies({
            url: "dev-vb4kx4fuiy6guge3.us.auth0.com"
        });
    };

    const onLogout = async () => {

        if (Capacitor.isNativePlatform()) {
            let currentLogo: string = localStorage.getItem("companyLogoUrl") ?? ""
            let currentColor: string = localStorage.getItem("formsPrimaryColor") ?? ""
            let currentLogo64: string = localStorage.getItem("logoBase64") ?? ""
            localStorage.clear();
            await indexedDBService.deleteItem('General', 'User')
            localStorage.setItem("companyLogoUrl", currentLogo)
            localStorage.setItem("formsPrimaryColor", currentColor)
            localStorage.setItem("logoBase64", currentLogo64)
            await logout({
                logoutParams: {
                    returnTo: appConfig.AUTH0_REDIRECTURI + 'login'
                },
                async openUrl(url) {
                    // Redirect using Capacitor's Browser plugin
                    await Browser.open({
                        url,
                        windowName: "_self"
                    });
                }
            });
        }
        else {
            logout({ logoutParams: { returnTo: window.location.origin + '/login' } });
            let currentLogo: string = localStorage.getItem("companyLogoUrl") ?? ""
            let currentColor: string = localStorage.getItem("formsPrimaryColor") ?? ""
            let currentLogo64: string = localStorage.getItem("logoBase64") ?? ""
            localStorage.clear();
            localStorage.setItem("companyLogoUrl", currentLogo)
            localStorage.setItem("formsPrimaryColor", currentColor)
            localStorage.setItem("logoBase64", currentLogo64)
        }
    };

    return (
        <>
            {user && user.userId > 0 && <TopNav onNavOpen={() => setOpen(true)} />}
            {user && user.userId > 0 && <SideNav
                onClose={() => setOpen(false)}
                open={open}
            />}

            {user && user.isActive && <LayoutRoot>
                <LayoutContainer>
                    <Outlet />
                </LayoutContainer>
            </LayoutRoot>}
            {user && user.userId > 0 && !user.isActive && <LayoutRoot>
                <LayoutContainer>
                    <h2>{user.message}</h2>
                </LayoutContainer>
            </LayoutRoot>}
            {user && !user.userId && !user.isActive && <LayoutRoot>
                <LayoutContainer>
                    <h2>{user.message}</h2>
                    <Button
                        onClick={onLogout}
                        color='primary'
                        variant='contained'
                        sx={{ width: "200px", margin: "0 auto" }}
                    >
                        Logout
                    </Button>
                </LayoutContainer>
            </LayoutRoot>}
        </>
    );
}
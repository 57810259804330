import ShoFormsClient from '../httpClient/ShoFormsClient';
import { showSnackbar } from './snackbar.service';

export const getCustomerList = async (request) => {
    try {
        const instance = ShoFormsClient();
        const response = await instance.post('/Customer/GetCustomerList', request);
        return response.data.customerList;
    } catch (error) {
        throw error;
    }
};

export const SaveDropboxAccessToken = async (request) => {
    try {
        const instance = ShoFormsClient();
        await instance.post('/Customer/SaveDropboxAccessToken', request);
    } catch (error) {
        showSnackbar('Failed To Save Access Token', 'error', 5000);
        throw error;
    }
};

export const GetCustomer = async () => {
    try {
        const instance = ShoFormsClient();
        let customerId = localStorage.getItem("customer");

        if (!customerId)
            customerId = "";
        let payload = { customerId: customerId };
        let response = await instance.post('/Customer/GetCustomer', payload);
        return response.data;
    } catch (error) {

    }
};

export const GetCustomerLogo = async (fileName: string) => {
    try {
        const instance = ShoFormsClient();
        let response = await instance.get(`/Customer/GetCustomerLogo/${fileName}`);
        return response.data;
    } catch (error) {
        throw error;
    }
}
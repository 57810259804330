import React, { useEffect } from 'react';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import Logo from "../../components/main-layout/Logo"
import { appConfig } from '../../services/config.service';

const CallbackMobileRedirect: React.FunctionComponent = () => {

    useEffect(() => {

        let urlParams = new URLSearchParams(window.location.search);
        let code: string = urlParams.get('code') + "";
        let callingSystem: string = urlParams.get('callingSystem') + "";
        code = decodeURIComponent(code);
        callingSystem = decodeURIComponent(callingSystem);
        //saveAccessCode(code);

        window.location.href = appConfig.MOBILE_URL_SCHEME + "profile?callingSystem=" + callingSystem + "&code=" + code;
    }, []);

    return (
        <Grid sx={{ flexGrow: 1 }} container spacing={2}>
            <Grid item xs={12}>
                <Grid container justifyContent="center">
                    <Card sx={{ maxWidth: 500 }}>
                        <Logo />

                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                Redirecting...
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                We are redirecting you back to ShoForms...
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default CallbackMobileRedirect;

// src/store/rolesSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IRolesState } from '../types/reduxStore';

const initialState: IRolesState = {
    userPageAccess: {}
};

export const rolesSlice = createSlice({
    name: 'roles',
    initialState,
    reducers: {
        updateRoles: (state, action: PayloadAction<IRolesState>) => {
            if (action.payload) {
                state.userPageAccess = action.payload.userPageAccess;
            } else {
                return initialState;
            }
        },
    },
});

export const { updateRoles } = rolesSlice.actions;

export default rolesSlice.reducer;

import axios, { AxiosInstance } from 'axios';
import { appConfig } from '../services/config.service';

const ShoFormsClient = (): AxiosInstance => {
    const instance: AxiosInstance = axios.create({
        baseURL: appConfig.API_URL,
    });

    var token = localStorage.getItem('auth0token')

    if (token) {
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }

    return instance;
};

export default ShoFormsClient;

import { forceUpdate } from "@ionic/pwa-elements/dist/types/stencil-public-runtime";
import { GetCustomerLogo } from "../../services/customer.service";
import React, { useEffect, useState, useReducer } from "react";
import { IncomingMessage } from "http";

export default function Logo() {

    const style = {
        height: "70px",
        backgroundColor: "#FFFFFF",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    };

    function getStringAfterCharacter(str, char) {
        const index = str.indexOf(char);
        if (index === -1 || index === str.length - 1) {
            return '';
        }
        return str.substring(index + 1);
    }

    const blobToBase64 = async (blob) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                resolve(reader.result);
            };
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
    };

    const convertPngToBase64 = async (filename) => {
        try {
            const response = await fetch(`${process.env.PUBLIC_URL}/${filename}`);
            if (!response.ok) {

            }
            const blob = await response.blob();
            const base64 = await blobToBase64(blob);
            const removedPrefix = getStringAfterCharacter(base64, ',')
            return removedPrefix
        } catch (error) {

        }
    };

    const getDefaultBase64 = async () => {
        var template64 = localStorage.getItem('logoBase64') ?? await convertPngToBase64("logo.png")
        localStorage.setItem('processLogo', "Done")
        return template64
    }

    const [logoBase64, setLogoBase64] = useState(getDefaultBase64())

    useEffect(() => {
        const setLogo = async () => {
            getDefaultBase64().then((defaultLogo) => {
                setLogoBase64(defaultLogo)
            })
        }
        setLogo()
    }, [logoBase64]);
    return (
        <div style={style}>
            <div className="customer-logo">
                <img id="logo" alt="logo" src={`data:image/png;base64,${logoBase64}`} style={{ maxWidth: "20vh", maxHeight: "70px" }} />
            </div>
        </div>
    )
}

export const showSnackbar = (message, type, duration = 5000) => {
    const x = document.getElementById("snackbar") as HTMLElement;
    x.classList.add("show");
    x.style.display = "block";
    x.classList.add(type);
    x.innerText = message;
    setTimeout(() => {
        x.classList.remove("show");
        x.classList.remove(type);
        setTimeout(() => { x.style.display = "none"; }, 100);
    }, duration);
};

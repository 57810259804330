import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUserDetail, IUserResponse } from '../types/reduxStore';
import { IUserPageAccess } from '../types/manage';

const initialPageAccessState: IUserPageAccess[] = [];

const initialState: IUserDetail = {
    message: '',
    userId: 0,
    name: '',
    email: '',
    roleId: 0,
    roleName: '',
    isActive: false,
    customerId: 0,
    linkedToDropbox: false,
    //linkedToGoogleAccount: false,
    //linkedToDropboxAccount: false,
    //linkedToMicrosoftAccount: false,
    auth0UserId: '',
    //linkedGoogleUserId: null,
    //linkedDropboxUserId: null,
    //linkedMicrosoftUserId: null
    pageAccess: initialPageAccessState,
};

export const userResponseSlice = createSlice({
    name: 'userResponse',
    initialState,
    reducers: {
        updateUserResponse: (state, action: PayloadAction<IUserResponse>) => {
            if (action.payload) {
                if (action.payload.message) {
                    state.message = action.payload.message;
                }
                else {
                    state.message = "";
                }
                if (action.payload.userDetail) {
                    state.userId = action.payload.userDetail.userId;
                    state.name = action.payload.userDetail.name;
                    state.email = action.payload.userDetail.email;
                    state.roleId = action.payload.userDetail.roleId;
                    state.roleName = action.payload.userDetail.roleName;
                    state.isActive = action.payload.userDetail.isActive;
                    state.customerId = action.payload.userDetail.customerId;
                    state.linkedToDropbox = action.payload.userDetail.linkedToDropbox;
                    //state.linkedToGoogleAccount = action.payload.userDetail.linkedToGoogleAccount;
                    //state.linkedToDropboxAccount = action.payload.userDetail.linkedToDropboxAccount;
                    //state.linkedToMicrosoftAccount = action.payload.userDetail.linkedToMicrosoftAccount;
                    state.auth0UserId = action.payload.userDetail.auth0UserId;
                    //state.linkedGoogleUserId = action.payload.userDetail.linkedGoogleUserId;
                    //state.linkedDropboxUserId = action.payload.userDetail.linkedDropboxUserId;
                    //state.linkedMicrosoftUserId = action.payload.userDetail.linkedMicrosoftUserId;
                }
                if (action.payload.pageAccess) {
                    state.pageAccess = action.payload.pageAccess;
                }
            }
        },
    },
});

export const { updateUserResponse } = userResponseSlice.actions;

export default userResponseSlice.reducer;
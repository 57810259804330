import { useEffect, useReducer, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';
import { useStyles } from "../common/appStyles";
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { showSnackbar } from '../services/snackbar.service';
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';
import { IUserOption, IFormOption } from '../types/form-groups';
import { GetFormGroupMasters } from '../services/form-group-service';

const initialState = {
    formGroupId: 0,
    formGroupName: "",
    formIdList: [],
    userIdList: [],

    isValid: {
        name: false,
        email: false,
    },
    touched: {
        name: false,
        email: false,
    }
};

function reducer(state, action) {
    switch (action.type) {
        case 'SET_FIELD':
            return { ...state, [action.field]: action.value };
        case 'SET_VALID':
            return { ...state, isValid: { ...state.isValid, [action.field]: action.isValid } };
        case 'SET_TOUCHED':
            return { ...state, touched: { ...state.touched, [action.field]: true } };
        default:
            throw new Error();
    }
}

export default function CreateFormGroupDialog(props) {
    const { showDialog, titleText, selectedFormGroup, handleClose, handleSave } = props;
    const classes = useStyles();
    const user = useSelector((state: RootState) => state.userDetail);

    const [state, dispatch] = useReducer(reducer, {
        ...initialState,
        formGroupId: selectedFormGroup.formGroupId,
        formGroupName: selectedFormGroup.formGroupName,
        formIdList: selectedFormGroup.formIdList ? selectedFormGroup.formIdList : [],
        userIdList: selectedFormGroup.userIdList ? selectedFormGroup.userIdList : [],
    });

    const { formGroupName, formGroupId, formIdList, userIdList, isValid, touched } = state;

    const [users, setUsers] = useState<IUserOption[]>([]);
    const [forms, setForms] = useState<IFormOption[]>([]);

    const getFormGroupMasters = () => {
        const request = { customerId: user.customerId };

        GetFormGroupMasters(request)
            .then(response => {
                setUsers(response.users);
                setForms(response.forms);
            })
            .catch((error) => {
                console.error(error);
            });
    }

    const setField = (field, value) => {
        dispatch({ type: 'SET_FIELD', field, value });
        validateField(field, value);
    };

    const setTouched = (field) => {
        dispatch({ type: 'SET_TOUCHED', field });
    };

    const validateField = (field, value) => {
        let isValid = false;
        switch (field) {
            case 'formGroupName':
                isValid = formGroupName?.trim().length > 0;
                break;
            default:
                break;
        }
        dispatch({ type: 'SET_VALID', field, isValid });
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();
        const updatedGroup = { formGroupId, formGroupName, formIdList, userIdList };

        handleSave(updatedGroup);
    };

    const isFormValid = isValid.formGroupName;

    useEffect(() => {

        if (formGroupName) {
            validateField('formGroupName', formGroupName);
        }

        const fetchData = async () => {
            try {
                getFormGroupMasters();
            } catch (error) {
                showSnackbar('Error fetching data', 'error', 5000);
            }
        };
        fetchData();
    }, []);

    return (
        <div>
            <Dialog
                fullScreen
                open={showDialog}
                onClose={handleClose}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <Typography sx={{ flex: 1 }} variant="h6" component="div">
                            {titleText}
                        </Typography>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>

                <Grid container spacing={2} className={classes.shoFormContainer}>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <TextField
                                required
                                label="Group Name"
                                value={formGroupName}
                                onChange={(event) => setField('formGroupName', event.target.value)}
                                onBlur={() => setTouched('formGroupName')}
                                error={!isValid.formGroupName && touched.formGroupName}
                                helperText={!isValid.formGroupName && touched.formGroupName ? "Please enter a valid name" : ""}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}></Grid>

                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <InputLabel>Assign Form(s)</InputLabel>
                            <Select
                                labelId="role-label"
                                value={formIdList}
                                multiple={true}
                                label="Select Form(s)"
                                onChange={(event) => setField('formIdList', event.target.value)}
                            >
                                {forms.map((option: any) => (
                                    <MenuItem key={option.formId} value={option.formId}>
                                        {option.formName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <InputLabel>Assign User(s)</InputLabel>
                            <Select
                                labelId="role-label"
                                value={userIdList}
                                multiple={true}
                                label="Select Users(s)"
                                onChange={(event) => setField('userIdList', event.target.value)}
                            >
                                {users.map((option: any) => (
                                    <MenuItem key={option.userId} value={option.userId}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} justifyContent={'center'}>
                        <Button onClick={handleSubmit} variant="contained" disabled={!isFormValid}>
                            {formGroupId > 0 ? "Update Form Group" : "Create Form Group"}
                        </Button>
                    </Grid>
                </Grid>
            </Dialog>
        </div>
    );
}

import { Fragment, useState } from 'react';
import * as React from 'react';

import Grid from '@mui/material/Grid';

import UserList from '../components/UserList';
import PageHeader from '../components/PageHeader';
import UserDialog from '../components/UserDialog';
import { saveUser, ChangeUserPassword } from '../services/manage-service';
import { useStyles } from "../common/appStyles";
import { Button } from '@mui/material';
import { IUser } from '../types/users';
import { showSnackbar } from '../services/snackbar.service';
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';
import { PageEnum } from '../types/enums';

function Users(props) {
    const classes = useStyles();

    const userRole = useSelector((state: RootState) => state.roles);
    const currentPage = userRole.userPageAccess[PageEnum.Users];

    const [showUserDialog, setShowUserDialog] = useState(false);
    const [showBackButton] = useState(false);
    const [selectedUser, setSelectedUser] = React.useState<IUser>();

    const [pageTitle] = useState("Users");
    const [titleText, setTitleText] = useState("");


    const onEditUser = (row: any) => {
        setTitleText("Update User");
        setSelectedUser(row);
        setShowUserDialog(true);
    }

    const handleClose = () => {
        setShowUserDialog(false)
    }

    const handleSave = async (savedUser) => {
        try {
            await saveUser(savedUser);
            if (savedUser.userId === 0) {
                var email = { "email": savedUser.email }
                await ChangeUserPassword(email);
            }
            showSnackbar('Saved Successfully', 'success', 5000);
            setShowUserDialog(false);
        } catch (error) {
            showSnackbar('Error saving data', 'error', 5000);
        }

    }

    const handleChangePassword = async (email) => {
        try {
            await ChangeUserPassword(email)
            showSnackbar('Sent Successfully', 'success', 5000);
        } catch (error) {
            showSnackbar('Error Sending Email', 'error', 5000);
        }
    }

    const onBack = () => { }

    const onAddNewUser = () => {
        setTitleText("Add User");
        setSelectedUser({
            userId: 0,
            name: "",
            email: "",
            roleId: 2,
            customerId: 1,
            roleName: "",
            isActive: true,
            auth0UserId: '',
        });
        setShowUserDialog(true);
    }

    return (
        <Fragment>
            <Grid
                container
                justifyContent={'center'}
                spacing={1}
                className={classes.container}
            >
                <Grid item sm={12} className='justify-center'>
                    <PageHeader title={pageTitle} showBackButton={showBackButton} onBackClick={onBack} />
                    {currentPage?.canView && currentPage?.canAdd &&
                        <Button className='btn-theme-toggle' onClick={() => onAddNewUser()} variant="contained">
                            Add New User
                        </Button>}
                </Grid>
                {currentPage?.canView && <Grid item sm={12}>
                    {!showUserDialog && <UserList onEditUser={onEditUser} />}

                    {showUserDialog && <UserDialog
                        showDialog={showUserDialog}
                        selectedUser={selectedUser}
                        titleText={titleText}
                        handleClose={handleClose}
                        handleSave={handleSave}
                        handleChangePassword={handleChangePassword} />}

                </Grid>}

                {!currentPage?.canView && <h1>You don't have access to Users Page</h1>}
            </Grid>
        </Fragment>
    );
}

export default Users;
